

.title_mobile {
  display: none;
}
.only_mobile {
  display: none;
}

body, html {
  background: white;
}

@media (min-width: 768px) {
  //footer
  .logo_footer {
    max-width: 300px;
  }
  .form_nl_wrp {
    padding-left: 10px;
  }
}


@media (min-width: 1536px) {
}
@media (min-width: 2200px) {
}
@media (min-width: 1399px) {
}
@media (max-width: 1279px) {
}
@media (max-width: 1199px) {
}
@media (max-width: 1024px) {

  .hp_cat .cnt_c {
    display: block;
    margin-bottom: 100px;
  }
  footer .bt .ctr {
    display: none;
  }
  .title_mobile {
    display: block;
    padding: 5px;
    font-weight: 700;
  }
  .blocchi_alternati {
    .sc_grid {
      .rt {
        //padding: 10px;
        height: 100%;
      }
      .tx {
        padding-right: 10px;
        line-height: normal;
        max-height: 70%;
        overflow: scroll;
      }
    }
  }

  .cms_famiglia.top_slide .h1 {
    font-size: 50px;
    line-height: 54px;
  }
  .cms_famiglia.top_slide .h2 {
    font-size: 40px;
    line-height: 44px;
  }
  .single_menu_sticky .titolo_vino {
    display: none;
  }
  .single_menu_sticky.stuck {
    padding-right: 70px;
  }

  .content_tenuta {
    #territorio_waypoint {
      .right {
        text-align: center;
        img {
          display: inline-block;
        }
      }
      .left {
        width: 90%;
        .text {
          padding: 0;
        }
        &:after {
          display: none;
        }
      }
      padding-bottom: 60px;
    }
    #vigneti_waypoint, #tenuta_waypoint {
      .left {
        width: 90%;
        .text {
          padding: 0;
        }
        &:after {
          display: none;
        }
      }
      padding-bottom: 60px;
    }
  }
  #vini_waypoint {
    .text {
      width: 100%;
    }
  }
  #ospitalita_waypoint, #degustazioni_waypoint {
    .top_banner {
      height: 400px;
    }
    .sc_layout3.esp .ctr {
      padding-left: 5px;
      padding-right: 5px;
    }
    .sc_grid .owl_hp_grid {
      margin-bottom: 0px;
    }
    .sc_grid.right, .sc_grid {

      .rt {
        //padding: 10px;
        height: 100%;
        text-align: center;
        .lg {
          text-align: center;
          img {
            max-width: 150px;
            display: inline-block;
          }
        }
        .lk {
          float: none;
          display: inline-block;
          margin: 20px;
        }
      }
      .tx {
        padding-right: 0;
        line-height: normal;
        max-height: 70%;
        overflow: scroll;
        text-align: center;
      }
    }
  }

  .single_annata_standard.top_slide .center_bottiglia img {
    max-height: 480px;
  }
  .single_annata_standard.top_slide .left_txt {
    padding-top: 0;
  }
  .single_annata_standard.top_slide .left_txt .titolo {
    font-size: 48px;
    line-height: 50px;
  }
  .single_annata_standard.top_slide .left_txt .sc_list {
    margin-top: 20px;
  }
  .single_annata_standard.top_slide .left_txt .sc_list.absolute {
    bottom: 10px;
  }

  .content_vino {
    #territorio_waypoint {
      .right {
        text-align: center;
        img {
          display: inline-block;
        }
      }
      .left {
        width: 90%;
        .text {
          padding: 0;
        }
        &:after {
          display: none;
        }
      }
      padding-bottom: 60px;
    }
    #terra_waypoint, #uva_waypoint, #cantina_waypoint, #premi_waypoint {
      .left {
        width: 90%;
        .text {
          padding: 0;
        }
        &:after {
          display: none;
        }
      }
      padding-bottom: 60px;
    }
  }

  #navigator_waypoint {
    margin-top: 60px;
    margin-bottom: 60px;
    .left_link, .right_link {
      display: none;
    }
  }

  .single_annata_storica .right_txt .txt_annata, .single_annata_storica .right_txt .txt_degustazione {
    padding-right: 10px;
  }
  .single_annata_storica .left_annate .scroller_annate_wrp li a .pointer:before {
    left: 50%;
  }
  .single_annata_storica .left_annate .scroller_annate_wrp li a.active .pointer:after {
    left: 50%;
  }
  .single_annata_storica .center_bottiglia img {
    max-height: 600px;
  }

  header .sh {
    top: 110px;
  }
  header nav {
    top: 110px;
    padding: 100px 10px 0px 10px;
  }
  header nav .bt {
    text-align: center;
    padding: 20px 0px 20px 0px;
  }
  header nav .dv .h1 {
    margin-bottom: 14px;
  }
  header nav .dv .nv a {
    padding: 15px 0px;
  }
}

@media (max-width: 1023px) {
  .single_menu_sticky.stuck {
    padding-right: 0px;
  }
  .title_mobile {
    display: block;
    padding: 5px;
    font-weight: 700;
  }

  .hp_cat .cnt_c {
    display: block;
    margin-bottom: 100px;
  }
  .blocchi_alternati {
    .sc_grid {
      .rt {
        padding: 10px;
        height: 100%;
      }
      .tx {
        padding-right: 0;
        line-height: normal;
        max-height: 70%;
        overflow: scroll;
      }
    }
  }

  .cms_famiglia.top_slide .h1 {
    font-size: 50px;
    line-height: 54px;
  }
  .cms_famiglia.top_slide .h2 {
    font-size: 40px;
    line-height: 44px;
  }
  .single_menu_sticky .titolo_vino {
    display: none;
  }
  .content_tenuta {
    #territorio_waypoint {
      .right {
        text-align: center;
        img {
          display: inline-block;
        }
      }
      .left {
        width: 90%;
        .text {
          padding: 0;
        }
        &:after {
          display: none;
        }
      }
      padding-bottom: 60px;
    }
    #vigneti_waypoint, #tenuta_waypoint {
      .left {
        width: 90%;
        .text {
          padding: 0;
        }
        &:after {
          display: none;
        }
      }
      padding-bottom: 60px;
    }
  }
  #vini_waypoint {
    .text {
      width: 100%;
    }
  }
  #ospitalita_waypoint, #degustazioni_waypoint {
    .top_banner {
      height: 400px;
    }
    .sc_layout3.esp .ctr {
      padding-left: 5px;
      padding-right: 5px;
    }
    .sc_grid .owl_hp_grid {
      margin-bottom: 20px;
    }
    .sc_grid.right, .sc_grid {

      .rt {
        padding: 10px;
        height: 100%;
        text-align: center;
        .lg {
          text-align: center;
          img {
            max-width: 150px;
            display: inline-block;
          }
        }
        .lk {
          float: none;
          display: inline-block;
          margin-bottom: 5px;
          margin-right: 0;
        }
      }
      .tx {
        padding-right: 0;
        line-height: normal;
        max-height: 70%;
        overflow: scroll;
        text-align: center;
      }
    }
  }


  .single-cpt_vini {
    .single_annata_standard.top_slide {
      margin-bottom: 120px;

      .left_txt {
        padding-top: 40px;
        transform: translate(5px, 0px);

        .lg {
          display: none;
        }

        .tipologia {
          font-size: 15px;
        }

        .titolo {
          font-size: 32px;
          line-height: 42px;
          padding-top: 5px;
          padding-bottom: 5px;
        }

        .sc_list {
         // display: none;
        }
      }

      .center_bottiglia img {
        max-height: 400px;
        width: auto;
      }

      .grid {
        bottom: -80px;
      }
    }
  }
  .content_vino {
    #territorio_waypoint {
      .right {
        text-align: center;
        img {
          display: inline-block;
        }
      }
      .left {
        width: 90%;
        .text {
          padding: 0;
        }
        &:after {
          display: none;
        }
      }
      padding-bottom: 60px;
    }
    #terra_waypoint, #uva_waypoint, #cantina_waypoint, #premi_waypoint {
      .left {
        width: 90%;
        .text {
          padding: 0;
        }
        &:after {
          display: none;
        }
      }
      padding-bottom: 60px;
    }
  }

  #navigator_waypoint {
    margin-top: 60px;
    margin-bottom: 60px;
    .left_link, .right_link {
      display: none;
    }
  }

  .blocchi_alternati {
    .sc_layout3.esp .ctr {
      padding-left: 5px;
      padding-right: 5px;
    }
    .sc_grid .owl_hp_grid {
      margin-bottom: 20px;
    }
    .sc_grid.right, .sc_grid {

      .rt {
        padding: 10px;
        height: 100%;
        text-align: center;
        .lg {
          text-align: center;
          img {
            max-width: 150px;
            display: inline-block;
          }
        }
        .lk {
          float: none;
          display: inline-block;
          margin-bottom: 5px;
          margin-right: 0;
        }
      }
      .tx {
        padding-right: 0;
        line-height: normal;
        max-height: 70%;
        overflow: scroll;
        text-align: center;
      }
    }
  }
  .form_nl_wrp .footer-newsletter .form-text {
    width: 50%;
  }
}

@media (max-width: 767px) {

  .hide_on_mobile {
    display: none !important;
  }
  .only_mobile {
    display: block;
  }

  .title_mobile {
    display: block;
    padding: 5px;
    font-weight: 700;
  }

  .hp_cat .cnt_c {
    display: block;
    margin-bottom: 100px;
  }

  .top_banner_unset_mobile .sld {
    position: unset;
  }
  .top_banner_unset_mobile .sld .ct {
    position: unset;
    top: unset;
    transform: none;
  }

  .cms_famiglia.top_slide .h1 {
    font-size: 40px;
    line-height: 54px;
    color: black;
  }
  .cms_famiglia.top_slide .h2 {
    font-size: 30px;
    line-height: 44px;
    color: black;
  }
  .top_banner_unset_mobile.top_slide .h1_slide {
    font-size: 40px;
    line-height: 54px;
    color: black;
  }
  .top_banner_unset_mobile.top_slide .h2_slide {
    font-size: 30px;
    line-height: 44px;
    color: black;
    margin-bottom: 0;
  }

  .top_cms_famiglia .text {
    width: 95%;
  }
  .blocchi_alternati .sc_grid .tx {
    padding-right: 0;
    width: 95%;
  }

  footer .fst_row {
      padding:0 20px;
    }
  footer .tp .dv {
    padding-right: 0;
  }

  .logo_tenuta_mobile {
    text-align: center;
    img {
      display: inline-block;
    }
  }
  .sc_list.lista_vini_tenute .lt .slider_left {
    transform: none;
  }
  .slider_left .single_item {
    padding: 5px;
    .logo_tenuta {
      display: none;
    }
  }
  .slider_left .single_item .img_container .pc {
    width: 110px;
    margin: 0px;
    padding-left: 0px;
    img {
    width: 100px;
    }
  }
  .slider_left .single_item .img_container .tx {
    left: 120px;
    width: calc(100% - 120px);
  }
  .slider_left .single_item .titolo {
    font-size: 43px;
    line-height: 36px;
  }
  .slider_left .single_item .payoff {
    font-size: 16px;
  }

  .sc_list.lista_annate .cnt .dv .ct {
    padding-left: 10px;
  }

  .sc_list.lista_annate .lt .dv .ct .pc {
    width: 120px;
    margin: 0px;
    padding-left: 20px;
    img {
      width: 120px;
    }
  }
  .sc_list.lista_annate .lt .dv .ct .tx {
    left: 160px;
    width: calc(100% - 160px);
    h3 {
      margin-bottom: 0;
    }
  }

  .page-template-template-tenuta-singola {
    .single_menu_sticky {
      display: none;
    }
    .content_tenuta {
      border: none;
      .il_territorio {
        padding-top: 20px;
        padding-bottom: 20px;
      }
      .left {
        width: 90%;
        .text {
          padding-right: 0;
        }
        &:after {
          display: none;
        }
      }
      .right {
        width: 90%;
      }

    }
  }
  #vini_waypoint {
    padding-top: 20px;
    padding-bottom: 20px;
    .sc_layout1 {
      padding: 20px 0px;
    }
    .ctr {
      padding: 0px 10px;
    }
    h2.titolo {
      //text-align: left;
    }
    .text {
      width: 100%;
      //text-align: left;
    }
  }
  #ospitalita_waypoint, #degustazioni_waypoint {
    .top_banner {
      height: 400px;
    }
    .simple_h1_title {
      margin-bottom: 10px;
      font-size: 31px;
      line-height: 44px;
    }
    .sc_layout3.esp .ctr {
      padding-left: 5px;
      padding-right: 5px;
    }
    .sc_grid .owl_hp_grid {
      margin-bottom: 20px;
    }
    .sc_grid.right, .sc_grid {
      .rt {
        width: 100%;
        text-align: center;
        .lg {
          text-align: center;
          img {
            display: inline-block;
          }
        }
        .tx {
          padding: 0;
        }
      }
    }
  }

  .modulo_richiesta_disponibilita .container_form {
    padding-left: 5px;
    padding-right: 5px;
  }
  .modulo_richiesta_disponibilita .form_richiesta.active {
    padding-top: 20px;
    padding-bottom: 20px;
    width: 90%;
  }

  .lista_annate .tx .titolo {
    font-size: 34px;
    line-height: 32px;
  }

  .single-cpt_vini {
    .single_annata_standard.top_slide {
      margin-bottom: 120px;
      .left_txt {
        padding-top: 0;
        transform: translate(5px,-20px);
        .lg {
          display: none;
        }
        .tipologia {
          font-size: 15px;
        }
        .titolo {
          font-size: 32px;
          line-height: 42px;
          padding-top: 5px;
          padding-bottom: 5px;
        }
        .sc_list {
          display: none;
        }
        .sc_list.absolute {
          display: block;
          bottom: -30px;
        }
      }
      .center_bottiglia img {
        max-height: 220px;
        width: auto;
      }
      .grid {
        bottom: -80px;
      }
    }
    .single_menu_sticky {
      display: none;
    }
    .content_vino {

      border: none;
      .il_territorio {
        padding-top: 20px;
        padding-bottom: 20px;
      }
      .il_vino {
        padding-top: 40px;
        padding-bottom: 40px;
        .text {
          column-count: 1;
        }
      }
      .left {
        width: 90%;
        .text {
          padding-right: 0;
        }
        &:after {
          display: none;
        }
      }
      .right {
        width: 90%;
      }
    }
    #navigator_waypoint {
      margin-top: 60px;
      margin-bottom: 60px;
      .left_link, .right_link {
        display: none;
      }
    }
    .hp_esperienze_ {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .sc_grid .owl_hp_grid {
      margin-bottom: 20px;
    }
  }

  .lista_mobile_tenute {
    .sing_tenuta {
      text-align: center;
      margin-bottom: 80px;
      margin-top: 30px;
      img {
        display: inline-block;
        max-width: 200px;
      }
      img.logo_cabreo {
        max-width: 170px;
      }
      img.logo_campo {
        max-width: 180px;
      }
      img.logo_lafuga {
        max-width: 120px;
      }
      img.logo_nozzole {
        max-width: 250px;
      }
      img.logo_calvano {
        max-width: 205px;
      }
      img.logo_vigne {

        max-width: 280px;
      }
      .titolo {

        font-family: 'Cormorant Garamond', serif;
        font-weight: 600;
        font-size: 40px;
        line-height: 40px;
        p {
          margin: 0;
          padding: 0;
        }
        padding-top: 10px;
        padding-bottom: 10px;
      }
      .tipologia {
        font-family: proxima-nova;
        text-transform: uppercase;
        font-weight: 300;
        font-size: 22px;
        letter-spacing: 1px;
      }
      a {
        color: $color_oro;
        font-family: proxima-nova;
        font-weight: 700;
        text-transform: uppercase;
        border-bottom: 1px solid $color_oro;
        @include trans-fx();
        text-decoration: none;
        &:hover {
          color: black;
          border-bottom: 1px solid black;
        }
        display: inline-block;
        margin-top: 20px;
      }
    }
  }

  .page-template-template-selezioni-produttori,.page-template-template-selezioni-territorio, .page-template-template-selezioni-tipologia {
    .selezioni_title .title_selezione {
      font-size: 36px;
    }
    .selezioni_title .logo_selezione img  {
      max-width: 60% !important;
    }
    .sc_list {
      .lg_mob {
        display: none;
      }
    }
  }

}
@media (max-width: 320px) {
}


