// Use this file to overwrite the basic Bootstrap variables and add your own variables
// To overwrite a Bootstrap variable you don´t have to touch the Bootstrap folder.
// Just copy a variable from src/sass/bootstrap4/_variables.scss, paste it here and edit the value.


//font size
/*
10px = 0.625rem
12px = 0.75rem
14px = 0.875rem
16px = 1rem (base)
18px = 1.125rem
20px = 1.25rem
24px = 1.5rem
30px = 1.875rem
32px = 2rem
 */

$color_oro: #ccae71;
$color_text_dark_grey: #051C2C;

$title_size_1023: 1.6rem;
$subtitle_size_1023: 1.2rem;

$font_heebo: 'Heebo', sans-serif;
$font_cormo: 'Cormorant Infant', serif;
