// This is an empty scss file for your custom styles - Please add your super duper awesome mega design styles here
//mixins functions

@mixin trans-fx() {
  transition-duration: 300ms;
  -webkit-transition-duration: 300ms;
  -moz-transition-duration: 300ms;
  -ms-transition-duration: 300ms;
  -o-transition-duration: 300ms;
}
@mixin a_style($the_color,$over_color) {
  a {
    color: $the_color;
    @include trans-fx();
    text-decoration: none;
  }
  a:hover {
    color: $over_color;
    text-decoration: none;
  }
}
.as_table {
  display: table;
  height: 100%;
  width: 100%;
  vertical-align: middle;
}
.as_cell {
  display: table-cell;
  vertical-align: middle;
}

.simple_cta_gold {
  color: $color_oro;
  font-family: proxima-nova;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 1px solid $color_oro;
  @include trans-fx();
  text-decoration: none;
  &:hover {
    color: black;
    border-bottom: 1px solid black;
  }

}
.simple_h1_title {
  font-family: 'Cormorant Garamond', serif;
  margin-bottom: 44px;
  font-size: 40px;
  line-height: 54px;

}
.simple_txt_excerpt {
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
}

//folonari
header .lang {
  .wpml-ls-legacy-list-horizontal {
    ul {
      li:first-child {
        border-right: 1px solid black;
      }
      a {
        padding-bottom: 0;
        padding-top: 0;
      }
      @include a_style($color_text_dark_grey,$color_oro);
    }
    .wpml-ls-current-language {
      span {
        font-weight: 700;
      }
    }
  }
}

body, html {
  font-family: "proxima-nova" !important;
  font-weight: 400;
}
footer {
  .tp{
    .h1, .nv {
      @include a_style(black, $color_oro);
    }
  }
  .fst_row {
    text-align: center;
    font-size: 12px;
  }
  .sec_row {
    font-size: 14px;
    line-height: 20px;
    padding-top: 15px;
    letter-spacing: 0;
  }
  .thr_row {
    width: 100%;
    padding-top: 15px;
    font-size: 14px;
    line-height: normal;
  }
  .four_row {
    padding-top: 15px;
    font-size: 14px;
    line-height: normal;
    @include a_style(white, $color_oro);
    a {
      padding-right: 20px;
      font-size: 20px;
    }
    a:last-child {
      padding-right: 0;
    }
    .ml {
      margin-top: 15px;
      margin-bottom: 30px;
      a {
        font-weight: 600;
        padding-right: 0;
        font-size: 16px;
      }
    }
    .ck {
      a {
        padding-right: 5px;
        font-size: 14px;
      }
    }
  }
}


//header
.navbar_top .dv a {
  @include trans-fx();
  &:hover {
    color: #ccae71;
  }
}
.sc_list .more {
  @include trans-fx();
  &:hover {
    color: #ccae71;
  }
}

//home
.top_banner .sld .lk {
  @include a_style(white,$color_oro);
  a {
    &:after {
      @include trans-fx();
    }
  }
  a:hover {
    &:after {
      background: $color_oro;
    }
  }
}
.hp_map {
  .lt {
    .cnt {
      @include a_style($color_oro,$color_text_dark_grey);
      a {
        &:after {
          @include trans-fx();
        }
      }
      a:hover {
        border-bottom: 1px solid $color_text_dark_grey;
      }
    }
  }
}
.owl_hp_second {
  .owl-dots.disabled {
    display: inline-block !important;
  }
}
.owl_hp_second .owl-dots {
  position: absolute;
  left: unset;
  right: 180px;
}

.hp_cat {
  .cnt {
    .lt {
      @include a_style($color_oro,$color_text_dark_grey);
      a {
        &:after {
          @include trans-fx();
        }
      }
      a:hover {
        border-bottom: 1px solid $color_text_dark_grey;
      }
    }
  }
}
.hp_esperienze {
  .ctr {
    .rt {
      @include a_style($color_oro,$color_text_dark_grey);
      a {
        &:after {
          @include trans-fx();
        }
      }
      a:hover {
        border-bottom: 1px solid $color_text_dark_grey;
      }
    }
  }
}
.hp_news {
  .ctr {
    .lt {
      @include a_style($color_oro,$color_text_dark_grey);
      a {
        &:after {
          @include trans-fx();
        }
      }
      a:hover {
        border-bottom: 1px solid $color_text_dark_grey;
      }
    }
  }
  .rt {
    @include a_style($color_oro,$color_text_dark_grey);
  }
}

.hp_news .lt .dtnav {
  .prev, .next {
    font-size: 50px;
    @include trans-fx();
    &:hover {
      color: $color_oro;
    }
  }

}
.owl_hp_fourth {
  overflow-y: hidden;
  .owl-item img {
    width: 100%;
    height: auto;
  }
}


//vini tenute
.slider_left {
  padding-top: 40px;
  padding-bottom: 20px;
  .single_item {
    padding: 20px;
    .number_position {
      display: none;
    }
    .img_container {
      .pc {
        text-align: left;
        width: 270px;
        margin: 0px;
        padding-left: 90px;
        img {
          display: inline-block;
          width: 160px;
          height: auto;
        }
      }
      .tx {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        left: 290px;
        width: 370px;
        padding-right: 20px;
        color: #000000;
      }
    }
    .tipologia {
      font-family: proxima-nova;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 1px;
    }
    .titolo {
      font-family: 'Cormorant Garamond', serif;
      font-weight: 400;
      font-size: 50px;
      line-height: 40px;
      p {
        margin: 0;
        padding: 0;
      }
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .payoff {
      padding-top: 10px;
      font-family: 'Cormorant Garamond', serif;
      font-weight: 500;
      font-size: 20px;
      line-height: normal;
      text-transform: uppercase;
    }
    .lk {
      @extend .simple_cta_gold;
      display: inline-block;
      margin-top: 20px;
    }
  }
}
.slider_right {
  padding-top: 20px;
  padding-bottom: 20px;
  top: 100px;
    .single_item {

      .number_position {
        display: none;
      }

      .img_container {
        .pc {
          text-align: center;
          img {
            display: inline-block;
            height: 300px;
            width: auto;
          }
        }
        .tx {
          text-align: center;
          .titolo {
            font-family: 'Cormorant Garamond', serif;
            font-weight: 400;
            font-size: 32px;
            line-height: 32px;
            padding-top: 10px;
            padding-bottom: 10px;
            p {
              margin: 0;
              padding: 0;
            }
          }
          .tipologia {
            font-family: proxima-nova;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 14px;
          }
        }
      }
    }
}
.sez_tip_title {
  background: white;
  border: 1px solid $color_oro;
  padding-top: 20px;
  padding-bottom: 20px;
  .h3_style {
    text-align: center;
    color: $color_oro;
    text-transform: uppercase;
    font-family: "Cormorant Garamond";
    font-size: 40px;
    font-weight: 400;
    margin-bottom: 10px;
  }
  .h4_style {
    text-align: center;
    color: $color_text_dark_grey;
    font-family: proxima-nova;
    font-size: 20px;
    font-weight: 300;
  }
}

//annate storiche
.lista_annate {
  .tx {
    .tipologia {
      font-family: proxima-nova;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 1px;
    }
    .titolo {
      font-family: 'Cormorant Garamond', serif;
      font-weight: 400;
      font-size: 50px;
      line-height: 40px;
      padding-top: 10px;
      padding-bottom: 10px;
      p {
        margin: 0;
        padding: 0;
      }
    }
    .payoff {
      padding-top: 10px;
      font-family: 'Cormorant Garamond', serif;
      font-weight: 500;
      font-size: 20px;
      line-height: normal;
      text-transform: uppercase;
    }
    .lk {
      @extend .simple_cta_gold;
      display: inline-block;
      margin-top: 20px;
      text-decoration: none !important;
      &:hover {
        color: black !important;
        border-bottom: 1px solid black !important;
      }
    }
  }
}
.cantina_storica {
  .lk {
    @extend .simple_cta_gold;
    display: inline-block;
    margin-top: 20px;
    text-decoration: none !important;
    &:hover {
      color: black !important;
      border-bottom: 1px solid black !important;
    }
  }
}

//singola annata storica
.single_annata_storica {
  background: #FBF9F5;
  padding-bottom: 80px;
  .left_annate {
    position: relative;
    width: 70%;
    .scroller_annate_wrp {
      margin-top: 80px;
      height: 150px;
      overflow-y: scroll;
      direction: rtl;
      ul {
        direction:ltr;
        margin-left: 40px;
      }
      li {
        direction:ltr;
        position: relative;
        margin-bottom: 20px;
        width: 70%;
        a {
          font-family: proxima-nova;
          font-weight: 300;
          font-size: 16px;
          @include trans-fx();
          .pointer:before {
            position: absolute;
            width: 9px;
            height: 9px;
            top: 50%;
            left: 30%;
            -webkit-transform: translate(-30%, -30%);
            -ms-transform: translate(-30%, -30%);
            transform: translate(-30%, -30%);
            background-color: #BCBCBC;
            border-radius: 50%;
            content: '';
          }
          &:hover {
            .pointer:before {
              background-color: #00305c;
            }
            .pointer:after {
              position: absolute;
              width: 20px;
              height: 20px;
              top: 50%;
              left: 30%;
              -webkit-transform: translate(-40%, -40%);
              -ms-transform: translate(-40%, -40%);
              transform: translate(-40%, -40%);
              border: 1px solid #00305c;
              border-radius: 50%;
              content: '';
            }
          }
        }
        a.active {
          .pointer:before {
            background-color: #00305c;
          }
          .pointer:after {
            position: absolute;
            width: 20px;
            height: 20px;
            top: 50%;
            left: 30%;
            -webkit-transform: translate(-40%, -40%);
            -ms-transform: translate(-40%, -40%);
            transform: translate(-40%, -40%);
            border: 1px solid #00305c;
            border-radius: 50%;
            content: '';
          }
        }
      }
    }
    .sc_list {
      position: absolute;
      bottom: 20px;
      left: 0;
      width: 100%;
      margin-bottom: 0;
      .link_last_annata {
        @extend .simple_cta_gold;
        display: inline-block;
      }
    }
  }
  .center_bottiglia {
    text-align: center;
    img {
      max-height: 800px;
      width: auto;
      display: inline-block;
    }
  }
  .right_txt {
    position: relative;
    .tipologia {
      font-family: proxima-nova;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 16px;
    }
    .titolo {
      font-family: 'Cormorant Garamond', serif;
      font-weight: 400;
      font-size: 52px;
      line-height: 52px;
      padding-top: 10px;
      padding-bottom: 10px;
      p {
        margin: 0;
        padding: 0;
      }
    }
    .txt_anno {
      font-family: 'Cormorant Garamond', serif;
      font-weight: 400;
      font-size: 52px;
      line-height: 52px;
      padding-top: 30px;
      padding-bottom: 30px;
      color: $color_oro;
      p {
        margin: 0;
        padding: 0;
      }
    }
    .txt_annata, .txt_degustazione {
      padding-top: 20px;
      padding-bottom: 20px;
      .text {
        font-family: proxima-nova;
        font-weight: 300;
        font-size: 16px;
        padding-top: 10px;
      }
    }
    .titolo_annata {
      font-family: proxima-nova;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 16px;
    }
    .titolo_degustazione {
      font-family: proxima-nova;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 16px;
    }
    .sc_list {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      margin-bottom: 0;
      .more {
        bottom: 0;
      }
    }
  }
}

//singola annata standard
.single_annata_standard.top_slide {
  .left_txt {
    color: white;
    position: relative;
    padding-top: 80px;
    .lg {
      margin-bottom: 40px;
    }
    .tipologia {
      font-family: proxima-nova;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 1px;
    }
    .titolo {
      font-family: 'Cormorant Garamond', serif;
      font-weight: 400;
      font-size: 52px;
      line-height: 52px;
      padding-top: 10px;
      padding-bottom: 10px;
      p {
        margin: 0;
        padding: 0;
      }
    }
    .sc_list {
      width: 100%;
      margin-bottom: 0;
      margin-top: 40px;
      .link_acquista {
        @extend .simple_cta_gold;
        display: inline-block;
        padding-right: 15px;
        &:hover {
          color: white;
          border-bottom: 1px solid white;
        }
      }

      .select_schede {
        color: black;
        font-family: proxima-nova;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
    .sc_list.absolute {
      position: absolute;
      bottom: 20px;
      left: 0;
      width: 100%;
      margin-bottom: 0;
      text-align: left;
      .link_schede {
        @extend .simple_cta_gold;
        display: inline-block;
        text-align: left;
        border: none;
        &:hover {
          border: none;
        }
      }
    }
  }
  .center_bottiglia {
    text-align: center;
    img {
      max-height: 700px;
      width: auto;
      display: inline-block;
    }
  }
  .right_txt {
    position: relative;
    width: 100%;
    .sc_list {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      margin-bottom: 0;
      text-align: center;
      .more {
        text-transform: uppercase;
        bottom: 0;
        right: 30%;
      }
    }
  }
}
.acquista_big {
  margin-top: 40px;
  width: 100%;
  background: $color_oro;
  text-align: center;
  padding: 10px;
  font-family: proxima-nova;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  @include a_style(white, black);
}
.single_menu_sticky {
  border-bottom: 1px solid rgba(112,112,112,.5);
  padding-top: 15px;
  padding-bottom: 15px;
  .titolo_vino {
    color: $color_oro;
    font-family: 'Cormorant Garamond', serif;
    font-weight: 400;
    font-size: 22px;
    line-height: 22px;
  }
  .with_sep {
    border-right: 1px solid rgba(112,112,112,.5);
    width: 100%;
    text-align: center;
  }
  .menu_item {
    a {
      font-family: proxima-nova;
      text-transform: uppercase;
      font-weight: 300;
      font-size: 14px;
      letter-spacing: 1px;
      position: relative;
      &:after {
        display: none;
        content: '';
        width: 100%;
        height: 4px;
        background: $color_oro;
        position: absolute;
        bottom: -13px;
        left: 0;
      }
      &:hover {
        font-weight: 600;
        &:after {
          display: block;
        }
      }
    }
    a.active {
      font-weight: 600;
      &:after {
        display: block;
        content: '';
        width: 100%;
        height: 4px;
        background: $color_oro;
        position: absolute;
        bottom: -13px;
        left: 0;
      }
    }
  }
}
.stuck {
  position:fixed;
  top:0;
  width: 100%;
  background: white;
  z-index: 99999;
}
.stuck.off {
  display: none;
}
.content_vino {
  border-left: 30px solid #898474;
  .il_vino, .la_terra, .l_uva, .in_cantina, .i_premi {
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
    .left {
      position: relative;
      height: 100%;
      &:after {
        display: block;
        content: '';
        width: 50%;
        height: 1px;
        background: #707070;
        position: absolute;
        bottom: -2px;
        left: 0;
      }
    }
    .titolo {
      font-family: 'Cormorant Garamond', serif;
      font-weight: 400;
      font-size: 40px;
      line-height: 54px;
      padding-bottom: 10px;
    }
    .left {
      width: 80%;
      .text {
        padding-right: 20%;
        font-family: "proxima-nova";
        font-weight: 300;
        font-size: 19px;
        line-height: 33px;
      }
    }
    .right {
      width: 100%;
    }
  }
  .il_vino {
    padding-top: 60px;
    padding-bottom: 60px;
    width: 90%;
    .text {
      column-count: 3;
      column-gap: 30px;
      font-family: "proxima-nova";
      font-weight: 300;
      font-size: 19px;
      line-height: 33px;
    }
  }
  .no_right_m {
    margin-right: 0 !important;
  }
}
.vini_navigator {
  margin-top: 120px;
  margin-bottom: 120px;
  background: #898474;
  padding: 30px 10px;
  color: white;
  .left_link, .right_link {
    position: relative;
    width: 100%;
    a {
      display: inline-block;
      width: 100%;
      font-family: 'Cormorant Garamond', serif;
      font-weight: 500;
      font-size: 20px;
      line-height: 2em;
      @include trans-fx();
      .fal {
        color: $color_oro;
      }
      strong {
        float: right;
        padding-right: 90px;
      }
      &:hover {
        color: $color_oro;
        .fal {
          color: $color_oro;
        }
      }
    }
    img {
      display: inline-block;
      max-height: 250px;
      position: absolute;
      top: -125px;
      right: 0;
    }
  }
  .left_link {
    a {
      .fal {
        float: right;
        padding-right: 20px;
      }
    }
  }
  .right_link {
    a {
      .fal {
        float: left;
        padding-left: 20px;
      }
      strong {
        float: left;
        padding-right: 0px;
        padding-left: 90px;
      }
    }
    img {
      right: unset;
      left: 0;
    }
  }
  .center_cta {
    line-height: 2em;
    a {
      @extend .simple_cta_gold;
      &:hover {
        color: white;
        border-bottom: 1px solid white;
      }
    }
  }
}

//single tenuta
.single_tenuta_standard.top_banner.top_slide {
  height: auto;
}
.single_tenuta_standard {
  .breadcrumbs {
    color: white;
    .dv .d {
      @include a_style(white,$color_oro);
      &:after {
        background: white;
      }
    }
  }
}
.content_tenuta {
  border-left: 30px solid #898474;
  .il_territorio, .i_vigneti, .la_tenuta, .i_vini, .degustazioni, .ospitalita {
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
    .left {
      position: relative;
      height: 100%;
      &:after {
        display: block;
        content: '';
        width: 50%;
        height: 1px;
        background: #707070;
        position: absolute;
        bottom: -2px;
        left: 0;
      }
    }
    .titolo {
      font-family: 'Cormorant Garamond', serif;
      font-weight: 400;
      font-size: 40px;
      line-height: 54px;
      padding-bottom: 10px;
    }
    .left {
      width: 80%;
      .text {
        padding-right: 20%;
        font-family: "proxima-nova";
        font-weight: 300;
        font-size: 19px;
        line-height: 33px;
      }
    }
    .right {
      width: 100%;
    }
  }
  .il_territorio {
    padding-top: 60px;
    padding-bottom: 60px;
    .left {
      .text {
        padding-right: 10%;
      }
    }
  }
  .no_right_m {
    margin-right: 0 !important;
  }
}
#vini_waypoint {
  padding-top: 60px;
  padding-bottom: 60px;
  .titolo {
    font-family: 'Cormorant Garamond', serif;
    font-weight: 400;
    font-size: 40px;
    line-height: normal;
    padding-bottom: 10px;
  }
  .text {
    font-family: "proxima-nova";
    font-weight: 300;
    font-size: 19px;
    line-height: 33px;
    width: 80%;
  }

  .lista_vini_tenute {
    a.more {
      bottom: 10px;
      z-index: 999;
    }
  }
}
#degustazioni_waypoint {
  .sc_grid {
    h1 {
      font-family: 'Cormorant Garamond', serif;
      font-weight: 400;
      font-size: 42px;
      line-height: 42px;
    }
    .titolo {
      font-family: 'Cormorant Garamond', serif;
      font-weight: 400;
      font-size: 40px;
      line-height: 54px;
      padding-bottom: 10px;
    }
    .tx {
      padding-top: 10px;
      font-family: "proxima-nova";
      font-weight: 300;
      font-size: 19px;
      line-height: 33px;
      padding-right: 20%;
    }
  }
  margin-bottom: 40px;
}
#ospitalita_waypoint {
  .sc_grid {
    h1 {
      font-family: 'Cormorant Garamond', serif;
      font-weight: 400;
      font-size: 42px;
      line-height: 42px;
    }
    .tx {
      padding-top: 10px;
      font-family: "proxima-nova";
      font-weight: 300;
      font-size: 19px;
      line-height: 33px;
      padding-right: 20%;
    }
    .titolo {
      font-family: 'Cormorant Garamond', serif;
      font-weight: 400;
      font-size: 40px;
      line-height: 54px;
      padding-bottom: 10px;
    }
  }
}



.yellow_btn {
  background: $color_oro;
  text-align: center;
  text-transform: uppercase;
  color: white;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.8rem 2.2rem;
  @include trans-fx();

  &:hover {
    background: $color_text_dark_grey;
    text-decoration: none;
  }
}
.the_form_style {

  .wpcf7-form-control-wrap {
    width: 100%;
    padding-bottom: 30px;
    display: block;

    input[type="text"] {
      background: white;
      width: 100%;
      border: none;
      border-bottom: 1px solid $color_oro;
      border-radius: 0;
      font-size: 0.75rem;
      padding: 15px;
      outline: 0;
      color: $color_text_dark_grey;
    }
    textarea {
      background: white;
      width: 100%;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid $color_oro;
      font-size: 0.75rem;
      padding: 15px;
      outline: 0;
      color: $color_text_dark_grey;
    }

    input[type="email"] {
      background: white;
      width: 100%;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid $color_oro;
      padding: 15px;
      font-size: 0.75rem;
      outline: 0;
      color: $color_text_dark_grey;
    }
  }

  .privacy_wrp {
    font-size: 0.75rem;
    font-weight: 400;
  }

  .send_wrp {
    text-align: right;

    .ajax-loader {
      display: block;
    }
  }

  input[type="submit"] {
    @extend .yellow_btn;
    transform: translateY(-10px);
  }

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $color_text_dark_grey;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $color_text_dark_grey;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: $color_text_dark_grey;
  }
}

.modulo_richiesta_disponibilita {
  .container_titolo {
    background: #F5F1E7;
    padding-top: 30px;
    padding-bottom: 30px;
    max-width: 100%;
  }
  .container_form {
    background: #FBF9F5;
    max-width: 100%;
  }
  margin-top: 40px;
  margin-bottom: 40px;
  .titolo {
    font-family: 'Cormorant Garamond', serif;
    font-weight: 400;
    font-size: 35px;
    line-height: 35px;
    text-align: center;
    cursor: pointer;
    @include trans-fx();
    &:hover {
      color: $color_oro;
    }
    &:after {
      display: inline-block;
      padding-left: 10px;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      font-family: "Font Awesome 5 Pro";
      font-weight: 900;
      content: "\f0d7";
      font-size: 20px;
    }
  }
  .titolo.active {
    &:after {
      content: "\f0d8";
    }
  }
  .form_richiesta {
    display: none;
    @extend .the_form_style;
    width: 80%;
    .wpcf7-form-control-wrap {
      input[type="text"] {
        background: none;
      }
      input[type="email"] {
        background: none;
      }
      textarea{
        background: none;
      }
    }
  }
  .form_richiesta.active {
    display: block;
    padding-top: 60px;
    padding-bottom: 40px;
    .singolo_item {
      width: 100%;
    }
    .send_wrp {
      text-align: center;
      input[type="submit"]{
        display: inline-block;
      }
      .wpcf7-spinner {
        float: left;
        position: absolute;
        padding-left: 20px;
      }
    }
    .privacy_wrp {
      text-align: center;
      input[type="checkbox"]{
        display: inline-block;
        padding-right: 10px;
      }
    }
  }

}


//distribuzione
.single_distribuzione {
  .sld h1 {
    color: black;
    text-align: left;
    font-size: 50px;
    font-style: italic;
  }
}
.blocchi_distribuzione {
  .container_titolo {
    padding-top: 30px;
    padding-bottom: 30px;
    border-top: 1px solid $color_oro;
    max-width: 100%;
  }
  .titolo {
    font-family: 'Cormorant Garamond', serif;
    font-weight: 400;
    font-size: 35px;
    line-height: normal;
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
    @include trans-fx();
    color: $color_oro;
    &:hover {
      color: black;
    }
    &:after {
      display: inline-block;
      padding-left: 10px;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      font-family: "Font Awesome 5 Pro";
      font-weight: 900;
      content: "\f0d7";
      font-size: 20px;
    }
  }
  .titolo.active {
    &:after {
      content: "\f0d8";
    }
  }
  .singola_distribuzione {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 40px;
    font-family: 'Cormorant Garamond', serif;
    font-weight: 300;
    font-size: 19px;
    line-height: 33px;
    .title {
      font-size: 28px;
      line-height: normal;
    }
    strong {
      color: $color_oro;
    }
    @include a_style(black, $color_oro);
    a {
      font-weight: 600;
    }
  }
}
.singola_distribuzione {
  display: none;
  width: 80%;
}
.singola_distribuzione.active {
  display: block;
}


//cms famiglia
.cms_famiglia.top_slide {
  .h1 {
      font-family: 'Cormorant Garamond', serif;
      font-weight: 400;
      font-size: 70px;
      line-height: 74px;
      margin-bottom: 8px;
  }
  .h2 {
    font-family: 'Cormorant Garamond', serif;
    font-weight: 400;
    font-size: 50px;
    line-height: 54px;
    font-style: italic;
  }
}
.top_cms_famiglia {
  .sc_layout1 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .titolo {
    font-family: 'Cormorant Garamond', serif;
    font-weight: 400;
    font-size: 40px;
    line-height: 54px;
    padding-bottom: 10px;
  }
  .text {
    font-family: "proxima-nova";
    font-weight: 300;
    font-size: 20px;
    line-height: 33px;
    width: 80%;
  }
}
.blocchi_alternati {
  .sc_grid {
    h1 {
      font-family: 'Cormorant Garamond', serif;
      font-weight: 400;
      font-size: 42px;
      line-height: 42px;
    }
    .tx {
      padding-top: 10px;
      font-family: "proxima-nova";
      font-weight: 300;
      font-size: 20px;
      line-height: 33px;
      padding-right: 20%;
    }
    .titolo {
      font-family: 'Cormorant Garamond', serif;
      font-weight: 400;
      font-size: 40px;
      line-height: 54px;
      padding-bottom: 10px;
    }
    .lk {
      @extend .simple_cta_gold;
    }
  }
}

footer {
  .last_footer {
    background: #051C2C;
    text-align: center;
    text-transform: uppercase;
    color: white;
    border-top: 1px solid white;
    margin-top: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
    .eu_flag {
      display: inline-block;
      width: 70px;
      height: auto;
    }
    .eu_txt {
      height: 12px;
      width: auto;
      display: inline-block;
      margin-left: 10px;
    }
  }
}


//selezioni
.selezioni_title {
  .logo_selezione {
    margin-bottom: 20px;
    img {
      max-width: 270px;
      height: auto;
    }
  }
  .title_selezione {
    margin-bottom: 20px;
    text-transform: uppercase;
    color: $color_oro;
    font-family: 'Cormorant Garamond', serif;
    font-weight: 400;
    font-size: 50px;
    line-height: normal;
  }
}
.selezioni_title.selezioni_cat_54 {
  .logo_selezione {
    img {
      max-width: 190px;
      height: auto;
    }
  }
}
.selezioni_title.selezioni_cat_56 {
  .logo_selezione {
    img {
      max-width: 230px;
      height: auto;
    }
  }
}
.selezioni_title.selezioni_cat_57 {
  .logo_selezione {
    img {
      max-width: 380px;
      height: auto;
    }
  }
}
.selezioni_title.selezioni_cat_58 {
  .logo_selezione {
    img {
      max-width: 350px;
      height: auto;
    }
  }
}
.selezioni_title.selezioni_cat_60 {
  .logo_selezione {
    img {
      max-width: 210px;
      height: auto;
    }
  }
}
.selezioni_title.selezioni_cat_61 {
  .logo_selezione {
    img {
      max-width: 400px;
      height: auto;
    }
  }
}

.selezioni_left .slider_left .single_item {
  .logo_prod {
   img {
     max-width: 150px;
     height: auto;
   }
  }
  .titolo {
    line-height: normal;
  }
}

//shop
.page-template-template-shop-winplatform {
  .ctr {
//    max-width: 100%;
    padding: 0;
  }
  .ct_text {
    padding-top: 0;
  }
}


//Nl footer

.yellow_cta {
  border-radius: 0;
  border: none;
  background: white;
  padding: 10px;
  text-transform: uppercase;
  text-align: left;
  font-size: 1rem;
  color: black;
  width: 100%;
}

.yellow_btn {
  background: $color_oro;
  text-align: center;
  text-transform: uppercase;
  color: white;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.8rem 2.2rem;
  @include trans-fx();

  &:hover {
    background: white;
    color: $color_text_dark_grey;
    text-decoration: none;
  }
}

.form_nl_wrp {
  input[type="email"] {
    @extend .yellow_cta;
  }

  input[type="submit"] {
    @extend .yellow_btn;
  }
  input[type="checkbox"] {
    display: inline-block;
  }

  .footer-newsletter {
    .form-text {
      border: none;
      padding: 16px;
      font-size: 14px;
      line-height: 16px;
      position: relative;
      background: #fff;
      color: $color_text_dark_grey;
      font-style: italic;
      height: 49px;
      display: inline-block;
      width: 60%;
      transform: translateY(-1px);
    }
    .form-btn {
      border: none;
      background-color: $color_oro;
      width: 150px;
      color: white;
      height: 49px;
      cursor: pointer;
      transition: all .3s ease-in-out;
      text-align: center;
      text-transform: uppercase;
      display: inline-block;
      padding: 0px;
      width: 38%;
      transform: translateX(-5px);
      @include trans-fx();
      &:hover {
        background: white;
        color: $color_text_dark_grey;
      }
    }
    .tnp-privacy-field {
      padding-top: 20px;
      a {
        @include trans-fx();
        &:hover {
          color: $color_oro !important;
        }
      }
    }
  }
}


//refine
.hp_esperienze_ {
  margin-top: 80px;
  margin-bottom: 80px;
}
.breadcrumbs {
  .last {
    font-weight: 700 !important;
  }
}
.seo_text {
  margin-top: 40px;
  font-weight: 300;
  font-size: 16px;
  line-height: normal;
}
.hp_news .rt .tx .t1 {
  font-weight: 700;
}
.hp_news .rt .tx .t3 {
  font-weight: 300;
  font-size: 16px;
  line-height: normal;
}
.sc_grid .rt .lg {
  transform: none;
  margin-bottom: 10px;
  img {
    max-width: 200px;
    max-height: 160px;
    height: auto;
  }
}
.sc_grid .rt a.lk {
  @extend .simple_cta_gold;
}
.sc_layout3.esp {

}
.sc_esp .dv .ct .tx .t1 {
  font-weight: 700;
}
.sc_esp .dv .ct .tx .t2 {
  font-weight: 300;
}
.sc_esp .dv .ct .tx a.lk {
  @extend .simple_cta_gold;
}

.single_menu_sticky.stuck {
  top:150px !important;
  border-top: 1px solid rgba(112,112,112,.5);
  z-index: 9998;
}

.navbar_top .dv a {
  letter-spacing: 1px;
}
.navbar_top .dv a.active {
  font-weight: 700;
}

.hp_cat .rt .pc .tx {
  top: 50px;
  transform: none;
  margin-top: 0;
  padding-right: 5px;
}
.sc_news .dv .ct .tx .t1 {
  font-weight: 700;
}
.full_ctr {
  max-width: 100%;
}
.no_pad_ctr {
  padding-left: 0;
  padding-right: 0;
}

.sc_list .cnt {
  max-height: 730px;
}
.sc_list .bg {
  max-height: 650px;
}
.sc_list .lt {
  max-height: 650px;
}
.sc_list .rt {
  max-height: 650px;
}

//singolo vino
.single_annata_standard.top_slide {
  position: relative;
  margin-bottom: 80px;
}
.single_annata_standard.top_slide .container-fluid {
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 100%;
  bottom: 0;
}
.single_annata_standard.top_slide .grid{
  position: absolute;
  width: 100%;
  bottom: -60px;
}


.single_annata_standard.top_slide .center_bottiglia img {
  max-height: 580px;
  width: auto;
  display: inline-block;
}

//immagine vini tenuta
.sc_list .lt .dv .ct .pc img{
  max-height: 600px;
}


.sc_list.lista_annate .bg .r {
  background-position: center right;
 // background-size: contain;
  background-size: cover;
  background-color: white;
}
.sc_list.lista_annate .lt .wt {
  width: calc(100% - 100px);
}
.sc_list.lista_annate .lt .dv .ct .pc {
  width: 270px;
  margin: 0px;
  padding-left: 0px;
}
.sc_list.lista_annate .lt .dv .ct .tx {
  left: 290px;
  width: 340px;
  .logo_tenuta {
    img {
      max-width: 200px;
    }
    margin-bottom: 20px;
  }
}

.slider_left .single_item .img_container .tx {
  .logo_tenuta {
    img {
      max-width: 200px;
    }
    margin-bottom: 20px;
  }
  .logo_tenuta.tenuta_820 {
    img {
      max-width: 180px;
    }
  }
  .logo_tenuta.tenuta_822 {
    img {
      max-width: 120px;
    }
  }
  .logo_tenuta.tenuta_824 {
    img {
      max-width: 120px;
    }
  }
  .logo_tenuta.tenuta_826 {
    img {
      max-width: 110px;
    }
  }
  .logo_tenuta.tenuta_828 {
    img {
      max-width: 160px;
    }
  }
}

.sc_list.lista_annate {
  .bg, .cnt, .lt {
    max-height: 650px;
  }
  .cnt {
    max-width: 100%;
    .dv {
      .ct {
        padding-left: 80px;
      }
    }
  }
}

.sc_list.lista_vini_tenute .lt .wt {
  width: calc(100% - 100px);
}
.sc_list.lista_vini_tenute .lt {
  overflow: unset;
  .slider_left {
    transform: translateX(-90px);
  }
  .ar_left {
    left: -50px;
  }
}


#vino_waypoint.distillato {
  width: 100%;
  .text {
    column-count:1;
    margin-bottom: 10px;
  }
}

.single_annata_standard.top_slide .left_txt {
  padding-left: 20px;
}
.single_annata_standard.top_slide .left_txt .sc_list.absolute {
  left: 20px;
}

@media (min-width: 1536px) {
  .single_annata_standard.top_slide .center_bottiglia img {
    max-height: 650px;
  }
  .sc_list .lt .dv .ct .pc img{
    max-height: 600px;
  }
  .sc_list.lista_annate {
    .bg, .cnt, .lt {
      max-height: 700px;
    }
  }
}
@media (min-width: 1900px) {
  .single_annata_standard.top_slide .center_bottiglia img {
    max-height: 700px;
  }
  .sc_list .lt .dv .ct .pc img{
    max-height: 600px;
  }
  .sc_list.lista_annate {
    .bg, .cnt, .lt {
      max-height: 800px;
    }
  }
}
@media (min-width: 2400px) {
  .single_annata_standard.top_slide .center_bottiglia img {
    max-height: 800px;
  }
  .sc_list .lt .dv .ct .pc img{
    max-height: 600px;
  }
  .sc_list.lista_annate {
    .bg, .cnt, .lt {
      max-height: 900px;
    }
  }
}







